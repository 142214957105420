<template>
    <div class="content_container provider_register">
        <div v-if="isUpdate" class="top_title">
            <h1>제품 수정</h1>
            <p>판매 중인 제품의 정보를 수정할 수 있습니다. ( <span>*</span> 필수입력 )</p>
        </div>
        <div v-else class="top_title">
            <h1>제품 등록</h1>
            <p>판매를 희망하시는 제품이 있으신가요? ( <span>*</span> 필수입력 )</p>
        </div>

        <div class="fill">
            <!--제품 정보 시작-->
            <div class="fill_product_wrap" id="productInfo_box">
                <p class="fill_box_title">제품 정보 입력<span class="star">*</span></p>

                <div class="fill_text">
                    <h1>제품명 <span class="star">*</span></h1>
                    <input type="text" placeholder="제품명을 입력해주세요." id="name" v-model="name" />
                </div>

                <div class="fill_explain">
                    <h1>제품 설명 <span class="star">*</span></h1>
                    <textarea
                        placeholder="제품 정보를 자세히 작성해주세요.&#10;회사명, 연락처 정보 입력 시 서비스 이용이 제한됩니다."
                        v-model="explanation"
                        id="explanation"
                    ></textarea>
                </div>

                <p class="fill_box_title">제품 이미지<strong>(각 이미지당 최대 2.5MB)</strong></p>
                <div class="fill_image">
                    <div class="product_image" v-for="i in 5" :key="i">
                        <img
                            v-if="!imageThumb[i - 1]"
                            src="@/assets/images/register/icon_image_add.svg"
                            class="add_image"
                            alt="더하기"
                            @click="$refs.imageInput.click()"
                        />
                        <img v-if="imageThumb[i - 1]" :src="imageThumb[i - 1]" alt="imageThumb" />
                        <span v-if="imageThumb[i - 1]" @click="deleteProductImage(i - 1)">
                            <img src="@/assets/images/register/icon_image_remove.svg" alt="빼기" />
                        </span>
                    </div>
                    <input
                        type="file"
                        style="display: none"
                        multiple
                        @change="onImageSelected($event)"
                        accept="image/*"
                        capture="camera"
                        ref="imageInput"
                        @click="
                            () => {
                                this.$refs.imageInput.value = '';
                            }
                        "
                    />
                </div>
            </div>
            <!--제품 정보 시작-->

            <!--공급 정보 시작-->
            <div class="fill_provide_wrap" id="supplyInfo_box">
                <p class="fill_box_title">공급 정보 입력<span class="star">*</span></p>
                <!--카테고리 시작-->
                <div class="fill_select">
                    <h1>카테고리 <span class="star">*</span></h1>
                    <select v-model="category" id="category">
                        <option value="">선택</option>
                        <option
                            v-for="(item, i) in this.basicData.category.filter((el) => el.level == 1)"
                            :key="i"
                            :value="item.category_pk"
                        >
                            {{ item.content }}
                        </option>
                    </select>
                </div>
                <!--카테고리 끝-->

                <!--예상 마진 시작-->
                <div class="fill_select">
                    <h1>예상 마진 <span class="star">*</span></h1>
                    <select v-model="margin" id="margin">
                        <option value="">선택</option>
                        <option v-for="(item, i) in this.basicData.margin" :key="i" :value="item.margin_pk">
                            {{ item.content }}
                        </option>
                    </select>
                </div>
                <!--예상 마진 끝-->

                <!--MOQ 시작-->
                <div class="fill_select">
                    <h1>MOQ(최소구매수량) <span class="star">*</span></h1>
                    <select v-model="moq" id="moq">
                        <option value="">선택</option>
                        <option v-for="(item, i) in this.basicData.moq" :key="i" :value="item.moq_pk">
                            {{ item.content }}
                        </option>
                    </select>
                </div>
                <!--MOQ 끝-->

                <!--제조기간 시작-->
                <div class="fill_select">
                    <h1>제조기간 <span class="star">*</span></h1>
                    <select v-model="start" id="start">
                        <option value="">선택</option>
                        <option v-for="(item, i) in this.basicData.start" :key="i" :value="item.start_pk">
                            {{ item.supply_content }}
                        </option>
                    </select>
                </div>
                <!--제조기간 끝-->

                <!--구입가능 샘플수량 시작-->
                <div class="fill_number">
                    <h1>구입가능 샘플수량 <span class="star">*</span></h1>
                    <input type="number" placeholder="숫자만 입력해주세요." v-model="quantity" id="quantity" />
                </div>
                <!--구입가능 샘플수량 끝-->

                <!--친환경 시작-->
                <div class="fill_select">
                    <h1>친환경 여부<span class="star">*</span></h1>
                    <select v-model="eco" id="eco">
                        <option value="">선택</option>
                        <option value="true">그린 제품</option>
                        <option value="false">일반 제품</option>
                    </select>
                </div>
                <!--친환경 끝-->
            </div>
            <!--공급 정보 끝-->

            <!--소비자가 시작-->
            <div class="fill_price" id="customerPrice_box">
                <p class="fill_box_title">소비자가<span class="star">*</span></p>
                <div class="fill_select">
                    <!-- <h1>소비자가</h1> -->
                    <select v-model="consumer_price_exposure_yn" id="customerPrice">
                        <option value="공개">공개</option>
                        <option value="회원공개">회원공개</option>
                        <option value="비공개">비공개</option>
                    </select>
                    <div class="fill_number">
                        <NumberInput
                            :propValue="consumer_price"
                            :eventName="'consumer-price'"
                            :placeholder="'소비자가(원)을 입력해주세요.'"
                            @consumer-price="bindConsumerPrice"
                        />
                    </div>
                </div>
            </div>
            <!-- 소비자가 끝 -->

            <!-- 공급가 시작 -->
            <div class="fill_price" id="supplyPrice_box">
                <p class="fill_box_title">공급가<span class="star">*</span></p>
                <div class="fill_select">
                    <!-- <h1>공급가</h1> -->
                    <select v-model="supply_price_exposure_yn" id="supplyPrice">
                        <option value="공개">공개</option>
                        <option value="회원공개">회원공개</option>
                        <option value="비공개">비공개</option>
                    </select>
                    <div class="fill_number">
                        <NumberInput
                            :propValue="supply_price"
                            :eventName="'supply_price'"
                            :placeholder="'공급가(원)을 입력해주세요.'"
                            @supply_price="bindSupplyPrice"
                        />
                    </div>
                </div>
            </div>
            <!--공급가 끝-->

            <!-- 공급 제품 특징 시작 -->
            <div class="fill_way fill_check" id="condi_box">
                <h1 class="fill_box_title">공급 제품 특징<span class="star">*</span></h1>
                <div class="type">
                    <label v-for="(item, i) in this.basicData.condi" :key="i">
                        <input
                            :id="`condi${item.condi_pk}`"
                            type="checkbox"
                            name="condi"
                            v-model="condi"
                            :value="item.condi_pk"
                        />
                        {{ item.product_content }} <span></span>
                    </label>
                </div>
            </div>
            <!-- 공급 제품 특징 끝 -->

            <!-- 선호 공급 방식 시작 -->
            <div class="fill_way fill_check" id="way_box">
                <h1 class="fill_box_title">선호 공급 방식<span class="star">*</span><strong>(최대 4개)</strong></h1>
                <div class="type">
                    <label v-for="(item, i) in this.basicData.way" :key="i">
                        <input type="checkbox" name="way" :id="`way${i}`" v-model="way" :value="item.way_pk" />
                        {{ item.product_content }} <span></span>
                    </label>
                </div>
            </div>
            <!-- 선호 공급 방식 끝 -->

            <!--희망 채널, 해시태그 시작-->
            <div class="fill_double">
                <div class="fill_channel fill_check" id="channel_box">
                    <h1 class="fill_box_title">선호 공급 채널<span class="star">*</span><strong>(최대 3개)</strong></h1>
                    <div class="type">
                        <label v-for="(item, i) in this.basicData.channel" :key="i">
                            <input
                                :id="`channel${item.channel_pk}`"
                                type="checkbox"
                                name="channel"
                                v-model="channel"
                                :value="item.channel_pk"
                            />
                            {{ item.content }} <span></span>
                        </label>
                    </div>
                </div>

                <div class="fill_hashtag_wrap">
                    <p class="fill_box_title">해시태그(검색키워드) 정보</p>
                    <div class="fill_text">
                        <h1>해시태그</h1>
                        <input
                            type="text"
                            placeholder="키워드를 검색하시고 Enter키를 눌러주세요"
                            name="keyword"
                            @keyup.enter="addHashTag($event)"
                        />
                        <div class="hashtag">
                            <div class="hashtag_word" v-for="(item, i) in this.hashtag" :key="i">
                                <span>{{ item }}</span>
                                <img src="@/assets/images/common/close.svg" @click="deleteHashTag(i)" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--희망 채널, 해시태그 끝-->

            <!--판매 지역 시작-->
            <div class="fill_place fill_check" id="area_box">
                <h1 class="fill_box_title">주력 판매 지역<span class="star">*</span></h1>
                <div class="box_partner_place">
                    <div class="type national">
                        <label class="check_all">
                            <input
                                type="checkbox"
                                id="allNational"
                                v-model="domestic"
                                @click="checkAll($event, '국내')"
                            />
                            국내
                        </label>
                        <label v-for="(item, i) in this.basicData.area.filter((el) => el.division == '국내')" :key="i">
                            <input
                                class="area_check"
                                type="checkbox"
                                name="national"
                                :id="`area${item.area_pk}`"
                                v-model="area"
                                :value="{ area_pk: item.area_pk, division: item.division }"
                            />
                            {{ item.content }}
                        </label>
                    </div>

                    <div class="type international">
                        <label class="check_all">
                            <input
                                type="checkbox"
                                id="allInternational"
                                v-model="world"
                                @click="checkAll($event, '전세계')"
                            />
                            전세계
                        </label>
                        <label
                            v-for="(item, i) in this.basicData.area.filter((el) => el.division == '전세계')"
                            :key="i"
                        >
                            <input
                                class="area_check"
                                type="checkbox"
                                name="international"
                                :id="`area${item.area_pk}`"
                                v-model="area"
                                :value="{ area_pk: item.area_pk, division: item.division }"
                            />
                            {{ item.content }}
                        </label>
                    </div>
                </div>
            </div>
            <!--판매 지역 끝-->

            <!--모집 마감일 시작-->
            <div class="fill_date_wrap">
                <p class="fill_box_title">모집 마감일</p>
                <div class="fill_date">
                    <h1>날짜</h1>
                    <input
                        type="date"
                        name="date"
                        data-placeholder="YYYY-MM-DD"
                        required
                        area-required="true"
                        v-model="finish_date"
                    />
                </div>
            </div>
            <!--모집 마감일 끝-->

            <!--첨부파일 시작-->
            <div class="fill_file_wrap">
                <p class="fill_box_title">첨부파일</p>
                <div class="fill_file">
                    <p @click="$refs.fileInput.click()">파일선택</p>
                    <input
                        type="file"
                        style="display: none"
                        @change="onFileSelected"
                        ref="fileInput"
                        @click="
                            () => {
                                this.$refs.fileInput.value = '';
                            }
                        "
                    />
                    <div v-if="file_name" class="fill_file_list">
                        <p>{{ file_name }}</p>
                        <button
                            type="button"
                            class="btn_delete"
                            @click="
                                () => {
                                    if (this.updateData && this.updateData.product_file[0].original_name === this.file_name) {
                                        this.deleted_file_pk = this.updateData.product_file[0].product_file_pk;
                                    }
                                    this.file = '';
                                    this.file_name = '';
                                }
                            "
                        >
                            <img src="@/assets/images/common/close_w.svg" />
                        </button>
                    </div>
                </div>
            </div>
            <!--첨부파일 끝-->

            <button v-if="isUpdate" type="submit" class="common_button submit_button" @click="submit(false)">
                수정하기
            </button>
            <button v-else type="submit" class="common_button submit_button" @click="submit(false)">
                등록하기
            </button>
        </div>
        <!-- <div v-if="isUpdate" class="btn_wrap">
            <button v-if="isUpdate" type="submit" class="common_button submit_button" @click="submit(false)">
                수정하기
            </button>
        </div> -->
    </div>
</template>

<script>
import { fetchData } from "@/utils/fetchData";
import { postData } from "@/utils/postData";
import { mapState } from "vuex";

import NumberInput from "@/components/NumberInput";

export default {
    beforeRouteLeave(to, from, next) {
        if (!this.isFormEmpty() && !this.postflag) {
            this.$store.commit("confirm", {
                content: "페이지를 떠나면 입력하신 정보가 사라집니다. 페이지를 떠나시겠습니까?",
                callback: () => {
                    this.isUpdate = false;
                    this.updateData = {};
                    this.$store.commit("setProductData", {});
                    this.$store.commit("setUpdate", false);
                    this.clearForm();
                    next();
                },
            });
        } else {
            this.isUpdate = false;
            this.updateData = {};
            this.$store.commit("setProductData", {});
            this.$store.commit("setUpdate", false);
            this.clearForm();
            next();
        }
    },
    mixins: [fetchData, postData],
    components: { NumberInput },
    data() {
        return {
            postflag: false, //등록 여부
            // 보낼 데이터
            name: "",
            explanation: "",
            image: [],
            imageThumb: [],
            category: "",
            margin: "",
            moq: "",
            start: "",
            condi: [],
            quantity: "",
            eco: "",
            consumer_price_exposure_yn: "공개",
            consumer_price: "",
            supply_price_exposure_yn: "공개",
            supply_price: "",
            way: [],
            channel: [],
            hashtag: [],
            area: [], //area_pk =< 9 : division = '국내', area_pk > 9 : division : '전세계'
            finish_date: "",
            file: "",

            // 받을 데이터
            basicData: {
                area: [],
                category: [],
                channel: [],
                margin: [],
                moq: [],
                start: [],
                condi: [],
                way: [],
            },
            domestic: false,
            world: false,
            isUpdate: false,
            deletedImages: [],
            file_name: "",
            deleted_file_pk: "",
        };
    },
    computed: {
        ...mapState("user", ["user"]),
        ...mapState("access_token", ["access_token"]),
    },
    watch: {
        $route(to) {
            if (to.path === "/register/provider") {
                this.clearForm();
            }
        },
        area: {
            deep: true,
            handler() {
                this.domestic =
                    this.area.filter((item) => item.division === "국내").length ===
                    this.basicData.area.filter((item) => item.division === "국내").length
                        ? true
                        : false;
                this.world =
                    this.area.filter((item) => item.division === "전세계").length ===
                    this.basicData.area.filter((item) => item.division === "전세계").length
                        ? true
                        : false;
            },
        },

        way: {
            deep: true,
            handler(newVal, oldVal) {
                if (newVal.length > 4) {
                    this.way = oldVal;
                }
            },
        },
        channel: {
            deep: true,
            handler(newVal, oldVal) {
                if (newVal.length > 3) {
                    this.channel = oldVal;
                }
            },
        },
    },
    created() {
        if (this.$route.path.includes("update/provider")) {
            if (this.$store.state.isUpdate === false) {
                this.$store.commit("alert", "잘 못된 접근입니다.");
                this.$store.commit("setProductData", {});
                this.$router.push("/");
            }
            this.updateData = this.$store.state.productData;

            this.isUpdate = true;
        }
        this.fetchBasicData();
        if (this.isUpdate) {
            if (this.$store.state.productData === null) {
                this.$store.commit("alert", "오류가 발생하였습니다.");
                this.$router.push("/");
            }
            this.name = this.$store.state.productData.name;
            this.explanation = this.$store.state.productData.explanation;
            //this.image = this.updateData.product_image.map((item) => item.path + item.save_name);
            this.imageThumb = this.$store.state.productData.product_image.map((item) => item.path + item.save_name);
            this.category = this.$store.state.productData.category;
            this.margin = this.$store.state.productData.margin;
            this.moq = this.$store.state.productData.moq;
            this.start = this.$store.state.productData.start;
            this.quantity = this.$store.state.productData.quantity;
            this.eco = this.$store.state.productData.eco_yn;
            this.consumer_price_exposure_yn = this.$store.state.productData.consumer_price_exposure_yn;
            this.consumer_price = String(this.$store.state.productData.consumer_price);
            this.supply_price_exposure_yn = this.$store.state.productData.supply_price_exposure_yn;
            this.supply_price = String(this.$store.state.productData.supply_price);

            this.hashtag = this.$store.state.productData.product_hashtag.map((item) => item.content);

            this.condi = this.$store.state.productData.product_condi.map((item) => {
                return item.condi_pk;
            });

            this.way = this.$store.state.productData.product_way.map((item) => item.way_pk);

            this.channel = this.$store.state.productData.product_channel.map((item) => item.channel_pk);
            this.area = this.$store.state.productData.product_area.map((item) => {
                return {
                    area_pk: item.area_pk,
                    division: Number(item.area_pk) <= 9 ? "국내" : "전세계",
                };
            });

            this.finish_date = this.$store.state.productData.finish_date
                ? this.$store.state.productData.finish_date.slice(0, 10)
                : "";
            if (this.$store.state.productData.product_file.length !== 0) {
                this.file_name = this.$store.state.productData.product_file[0].original_name;
            }
        }
    },

    methods: {
        // 기본 목록 데이터 불러오기
        fetchBasicData() {
            this.fetchData("/getBasic?page=r_provider", (data) => {
                this.basicData = data;
                if (this.isUpdate) {
                    this.domestic =
                        this.area.filter((item) => item.division === "국내").length ===
                        this.basicData.area.filter((item) => item.division === "국내").length
                            ? true
                            : false;
                    this.world =
                        this.area.filter((item) => item.division === "전세계").length ===
                        this.basicData.area.filter((item) => item.division === "전세계").length
                            ? true
                            : false;
                }
            });
        },
        // 제품 이미지 추가
        onImageSelected(event) {
            // 파일이 존재하는지
            if (event.target.files && event.target.files[0]) {
                if (event.target.files.length + this.imageThumb.length > 5) {
                    this.$store.commit("alert", "최대 5개를 선택해주세요");
                    return;
                }
                for (let i = 0; i < event.target.files.length; i++) {
                    // 2.5MB보다 크면 안받도록
                    if (event.target.files[i].size > 1048576 * 2.5) {
                        this.$store.commit("alert", "2.5MB 이하 이미지를 선택해 주세요");
                    }
                    // 이미지일 때만 this.image에 넣음
                    else if (
                        !event.target.files[i].type.includes("image") ||
                        event.target.files[i].type.includes("gif")
                    ) {
                        this.$store.commit("alert", "이미지만 선택해주세요. (.gif 제외)");
                    } else {
                        this.image.push(event.target.files[i]);

                        const reader = new FileReader();
                        reader.onload = (event) => {
                            this.imageThumb.push(event.target.result);
                        };
                        reader.readAsDataURL(event.target.files[i]);
                    }
                }
            }
        },
        // 제품 이미지 삭제
        deleteProductImage(i) {
            if (this.isUpdate) {
                if (this.updateData.product_image.find((item) => item.path + item.save_name === this.imageThumb[i])) {
                    const image = this.updateData.product_image.filter(
                        (item) => item.path + item.save_name === this.imageThumb[i],
                    )[0];
                    this.deletedImages.push(image.product_image_pk);
                    this.imageThumb.splice(i, 1);
                } else {
                    this.image.splice(i, 1);
                    this.imageThumb.splice(i, 1);
                }
            } else {
                this.image.splice(i, 1);
                this.imageThumb.splice(i, 1);
            }
        },

        // 판매 지역 전부 체크
        checkAll(event, division) {
            if (event.currentTarget.checked) {
                this.basicData.area.forEach((item) => {
                    if (!this.area.find((data) => data.area_pk === item.area_pk) && item.division === division) {
                        this.area.push({
                            area_pk: item.area_pk,
                            division: item.division,
                        });
                    }
                });
            } else {
                this.area = this.area.filter((item) => item.division !== division);
            }
        },
        // 해시태그 추가
        addHashTag(event) {
            // 공백 엔터시 예외
            if (event.currentTarget.value != "") {
                // 이미 있으면 제외
                if (!this.hashtag.includes(event.currentTarget.value)) {
                    this.hashtag.push(event.currentTarget.value);
                } else {
                    this.$store.commit("alert", "이미 입력한 해시태그 입니다.");
                }
            }
            event.currentTarget.value = "";
        },
        // 해시태그 제거
        deleteHashTag(i) {
            this.hashtag.splice(i, 1);
        },
        // 첨부파일
        onFileSelected(event) {
            // 파일이 존재하는지
            if (event.target.files && event.target.files[0]) {
                // 500MB보다 크면 안받도록
                if (event.target.files[0].size > 1048576 * 500) {
                    this.$store.commit("alert", "500MB 이하 파일을 선택해 주세요");
                } else {
                    this.file = event.target.files[0];
                    this.file_name = this.file.name;
                }
            }
        },
        submit(flag) {
            // 필수 입력 체크
            if (!this.name) {
                this.$store.commit("alert", "제품명을 입력해주세요.");
                this.toggleRequiredBox(document.getElementById("productInfo_box"));
                document.getElementById("name").focus();
            } else if (!this.explanation) {
                this.$store.commit("alert", "제품 설명을 입력해주세요.");
                this.toggleRequiredBox(document.getElementById("productInfo_box"));
                document.getElementById("explanation").focus();
            } else if (!this.category) {
                this.$store.commit("alert", "카테고리를 선택해주세요.");
                this.toggleRequiredBox(document.getElementById("supplyInfo_box"));
                document.getElementById("category").focus();
            } else if (!this.margin) {
                this.$store.commit("alert", "예상 마진을 선택해주세요.");
                this.toggleRequiredBox(document.getElementById("supplyInfo_box"));
                document.getElementById("margin").focus();
            } else if (!this.moq) {
                this.$store.commit("alert", "MOQ를 선택해주세요.");
                this.toggleRequiredBox(document.getElementById("supplyInfo_box"));
                document.getElementById("moq").focus();
            } else if (!this.start) {
                this.$store.commit("alert", "제조기간을 선택해주세요.");
                this.toggleRequiredBox(document.getElementById("supplyInfo_box"));
                document.getElementById("start").focus();
            } else if (this.quantity < 0 || this.quantity == "") {
                this.$store.commit("alert", "구입가능 샘플수량을 입력해주세요.");
                this.toggleRequiredBox(document.getElementById("supplyInfo_box"));
                document.getElementById("quantity").focus();
            } else if (this.eco == "") {
                this.$store.commit("alert", "친환경 여부를 입력해주세요.");
                this.toggleRequiredBox(document.getElementById("supplyInfo_box"));
                document.getElementById("eco").focus();
            } else if (this.consumer_price < 0 || this.consumer_price == "") {
                this.$store.commit("alert", "소비자가를 입력해주세요.");
                this.toggleRequiredBox(document.getElementById("customerPrice_box"));
                document.getElementById("customerPrice").focus();
            } else if (this.supply_price < 0 || this.supply_price == "") {
                this.$store.commit("alert", "공급가를 입력해주세요.");
                this.toggleRequiredBox(document.getElementById("supplyPrice_box"));
                document.getElementById("supplyPrice").focus();
            } else if (this.condi.length == 0) {
                this.$store.commit("alert", "공급 제품 특징을 선택해주세요.");
                this.toggleRequiredBox(document.getElementById("condi_box"));
                document.getElementById("condi1").focus();
            } else if (this.way.length == 0) {
                this.$store.commit("alert", "선호 공급 방식을 선택해주세요.");
                this.toggleRequiredBox(document.getElementById("way_box"));
                document.getElementById(`way1`).focus();
            } else if (this.channel.length == 0) {
                this.$store.commit("alert", "선호 공급 채널을 선택해주세요.");
                this.toggleRequiredBox(document.getElementById("channel_box"));
                document.getElementById(`channel1`).focus();
            } else if (this.area.length == 0) {
                this.$store.commit("alert", "주력 판매 지역을 선택해주세요.");
                this.toggleRequiredBox(document.getElementById("area_box"));
                document.getElementById("area1").focus();
            }
            // 문제 없으면 보내기
            else {
                this.toggleRequiredBox(null);

                const formData = new FormData();
                if (this.isUpdate) {
                    formData.append("product_pk", this.updateData.product_pk);
                    formData.append("del_image_pk", this.deletedImages);
                    formData.append("del_file_pk", this.deleted_file_pk);
                }
                formData.append("access_token", this.access_token); // 유저 토큰
                formData.append("name", this.name); // 제품명 (*)
                formData.append("explanation", this.explanation); // 제품 설명 (*)
                for (let i = 0; i < this.image.length; i++) {
                    formData.append(`image${i}`, this.image[i]); // 제품 이미지
                }
                formData.append("imageCount", this.image.length); // 이미지 개수
                formData.append("category", this.category); // 카테고리 (*)
                formData.append("margin", this.margin); // 예상 마진 (*)
                formData.append("moq", this.moq); // moq (*)
                formData.append("start", this.start); // start (*)
                formData.append("condi", this.condi.toString()); // 공급 제품 특징 (*)
                formData.append("quantity", this.quantity); // 구입가능 샘플수량 (*)
                formData.append("eco", this.eco); // 친환경 여부 (*)
                formData.append("consumer_price_exposure_yn", this.consumer_price_exposure_yn); // 소비자가 공개 여부 (*)
                formData.append("consumer_price", this.consumer_price); // 소비자가 (*)
                formData.append("supply_price_exposure_yn", this.supply_price_exposure_yn); // 공급가 공개 여부 (*)
                formData.append("supply_price", this.supply_price); // 공급가 (*)
                formData.append("price", this.price); // 제품 단가 (*)
                formData.append("way", this.way.toString()); // 선호 공급 방식 (*)
                formData.append("channel", this.channel.toString()); // 선호 공급 채널 (*)
                formData.append("area", this.area.map((item) => item.area_pk).toString()); // 희망 지역 (*)
                formData.append("hashtag", this.hashtag.toString()); // 해시태그
                formData.append("finish_date", this.finish_date); // 모집 마감일
                formData.append("file", this.file); // 첨부파일

                this.postData(
                    "/saveProduct",
                    formData,
                    () => {
                        this.postflag = true;
                        if (this.isUpdate) {
                            this.$store.commit("alert", "제품 정보가 수정되었습니다.");
                        } else {
                            this.$store.commit("alert", "제품이 등록되었습니다.");
                        }
                        if (flag == true) {
                            // TODO : 추가등록 confirm cancelCallback 구현
                            // this.$store.commit("confirm",{
                            //     content:'추가로 상품을 등록하시겠습니까?',
                            //     callback:()=>{
                            //         location.reload();
                            //     }
                            // })
                        } else {
                            if (this.isUpdate) {
                                this.$router.push("/mypage/ManageProduct");
                            } else {
                                this.$router.push("/product");
                            }
                        }
                    },
                    { "Content-Type": "multipart/form-data" },
                );
            }
        },
        toggleRequiredBox(element) {
            const productInfoBox = document.getElementById("productInfo_box");
            const supplyInfo = document.getElementById("supplyInfo_box");
            const customerPriceBox = document.getElementById("customerPrice_box");
            const supplyPriceBox = document.getElementById("supplyPrice_box");
            const condiBox = document.getElementById("condi_box");
            const wayBox = document.getElementById("way_box");
            const channelBox = document.getElementById("channel_box");
            const areaBox = document.getElementById("area_box");

            let requiredBoxes = [
                productInfoBox,
                supplyInfo,
                customerPriceBox,
                supplyPriceBox,
                condiBox,
                wayBox,
                areaBox,
                channelBox,
            ];
            requiredBoxes.forEach((el) => el.classList.remove("required_box"));

            if (element != null) {
                element.classList.add("required_box");
            }
        },
        bindConsumerPrice(value) {
            this.consumer_price = value;
        },
        bindSupplyPrice(value) {
            this.supply_price = value;
        },
        isFormEmpty() {
            if (
                !this.name &&
                !this.explanation &&
                !this.moq &&
                !this.category &&
                !this.margin &&
                !this.start &&
                !this.file &&
                !this.quantity &&
                !this.eco &&
                !this.consumer_price &&
                !this.supply_price &&
                this.condi.length === 0 &&
                this.image.length === 0 &&
                this.way.length === 0 &&
                this.channel.length === 0 &&
                this.hashtag.length === 0 &&
                this.area.length === 0
            ) {
                return true;
            } else {
                return false;
            }
        },
        clearForm() {
            this.name = "";
            this.explanation = "";
            this.image = [];
            this.imageThumb = [];
            this.category = "";
            this.margin = "";
            this.moq = "";
            this.start = "";
            this.condi = [];
            this.quantity = "";
            this.eco = "";
            this.consumer_price_exposure_yn = "공개";
            this.consumer_price = "";
            this.supply_price_exposure_yn = "공개";
            this.supply_price = "";
            this.way = [];
            this.channel = [];
            this.hashtag = [];
            this.area = []; //area_pk =< 9 : division = '국내', area_pk > 9 : division : =전세계;
            this.finish_date = "";
            this.file = "";
            this.bindConsumerPrice("");
        },
    },
};
</script>

<style scoped src="@/assets/css/layout_registerProvider.css"></style>
<style scoped>
/* .btn_wrap {
    width: 100%;
    display: flex;
    justify-content: center;
}
.btn_wrap button.common_button {
    border: none;
    background-color: var(--point-color);
    box-sizing: border-box;
    color: #fff;
    border-radius: var(--border-radius);
    cursor: pointer;
    font-size: var(--font-small);
    width: 178px;
    height: 50px;
    margin: 1rem;
    margin-top: 3rem;
}
.btn_wrap button.common_button:hover {
    background-color: var(--point-color-hover);
}
.sub_button {
    border: none;
    background-color: var(--primary-color1);
    box-sizing: border-box;
    color: #fff;
    border-radius: var(--border-radius);
    cursor: pointer;
    font-size: var(--font-small);
    width: 178px;
    height: 50px;
    margin: 1rem;
    margin-top: 3rem;
}

.sub_button:hover {
    background-color: var(--primary-color2);
} */
</style>

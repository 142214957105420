<template>
    <input
        type="text"
        :placeholder="placeholder ? placeholder : '입력해주세요.'"
        :value="inputValue"
        @input="inNumber($event)"
        maxlength="11"
    />
</template>

<script>
export default {
    props: {
        propValue: {
            type: String,
            required: true,
        },
        eventName: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            inputValue: "0",
        };
    },
    watch: {
        inputValue(value) {
            if (value === "") {
                this.inputValue = "";
            } else {
                const newValue = value.replace(/[^0-9]/g, "");

                newValue.length > 1 && newValue[0] === "0"
                    ? (this.inputValue = newValue.substring(1, newValue.length))
                    : (this.inputValue = newValue);
            }

            this.$emit(this.eventName, this.inputValue);
        },
    },
    mounted() {
        this.inputValue = String(this.propValue);
    },
    methods: {
        inNumber(event) {
            this.inputValue = event.target.value;
        },
    },
};
</script>

<style></style>
